<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/directionalTask' }">指派任务</el-breadcrumb-item>
          <el-breadcrumb-item>新增任务</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息<span style="color: red"> *</span></div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="任务名称：" prop="taskName">
                <el-input v-model="form.taskName" placeholder="请输入任务名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：" prop="">
                <el-input value="新创建" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="任务描述：" prop="taskDescribe">
                <el-input v-model="form.taskDescribe" placeholder="请输入任务描述"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 水表配置 -->
          <div class="config-title">
            <div class="form-title">
              水表信息<span style="color: red"> *</span>
            </div>
            <div>
              <el-button @click="addPatrol" type="primary" size="small">添加水表</el-button>
            </div>
          </div>
          <div class="table-list" v-if="selectPatrolData.length>0">
            <el-table :data="selectPatrolData" style="width: 100%">
              <el-table-column prop="userCode" label="用户编号"></el-table-column>
              <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
              <el-table-column prop="userName" label="用户名称"></el-table-column>
              <el-table-column prop="address" label="水表地址"></el-table-column>
              <el-table-column prop="useProperty" label="用水性质"></el-table-column>
              <el-table-column prop="originPlace" label="水表产地"></el-table-column>
              <el-table-column prop="type" label="水表类型"></el-table-column>
              <el-table-column prop="caliber" label="水表口径"></el-table-column>
              <el-table-column prop="model" label="水表型号"></el-table-column>
              <el-table-column prop="valve" label="阀门状态"></el-table-column>
              <el-table-column prop="readType" label="抄表方式"></el-table-column>
              <el-table-column prop="capacity" label="水表容量"></el-table-column>
              <el-table-column prop="watchCase" label="表壳号"></el-table-column>
              <el-table-column prop="coreNum" label="表芯号"></el-table-column>
              <el-table-column prop="runningStatus" label="水表状态"></el-table-column>
              <el-table-column prop="position" label="安装位置"></el-table-column>
              <el-table-column prop="createTime" label="安装时间">
                <template slot-scope="scope">
                  {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          
          <!-- 外勤人员配置 -->
          <div class="config-title">
            <div class="form-title">
              外勤人员配置<span style="color: red"> *</span>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="外勤人员：">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList">
                    <el-tag style="margin-right: 4px;">
                      {{ staffList }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择外勤人员</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止日期：" prop="date">
                <el-date-picker
                    :picker-options="pickerOptions"
                    style="width: 335px"
                    v-model="form.date"
                    type="date"
                    value-format="timestamp"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
         
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/directionalTask')">取消</el-button>
            <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 水表侧滑 -->
      <el-drawer
        title="添加水表"
        :with-header="false"
        :visible.sync="patrolDrawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">添加水表</div>
        <div class="search">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="水表编号">
              <el-input type="text" size="small" v-model="formInline.waterMeterCode" clearable placeholder="请输入水表编号"></el-input>
            </el-form-item>
            <el-form-item label="用户名称">
              <el-input type="text" size="small" v-model="formInline.userName" clearable placeholder="请输入用户名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="searchQuery">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-content table-water-tree">
          <div class="table">
            <el-table :data="tableDataCp" height="600" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="waterRadio" @change="checkeChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
              <el-table-column prop="userCode" label="用户编号"></el-table-column>
              <el-table-column prop="userName" label="用户名称"></el-table-column>
              <el-table-column prop="address" label="水表地址"></el-table-column>
              <el-table-column prop="position" label="安装位置"></el-table-column>
            </el-table>
            <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="patrolDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="patrolDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 外勤人员 -->
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">选择外勤人员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="50">
                  <template slot-scope="scope">
                    <el-radio-group v-model="staffRadio" @change="checked(scope.row)">
                      <el-radio :label="scope.row.id">{{''}}</el-radio>
                    </el-radio-group>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs'
import layTime from '@/components/layTime'
export default {
  components: {
    page,
    layTime
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      form: {},
      formInline: {},
      rules: {
        taskName:[{ required: true, message: '必填项', trigger: 'blur' }],
        taskDescribe:[{ required: true, message: '必填项', trigger: 'blur' }],
        userInfo:[{ required: true, message: '必填项', trigger: 'blur' }],
        watermeterInfo:[{ required: true, message: '必填项', trigger: 'blur' }],
        date:[{ required: true, message: '必填项', trigger: 'change' }],
      },

      waterRadio: 0,
      patrolDrawer: false,
      tableDataCp:[],
      tableSelectList: {},
      selectPatrolData: [],
      tableLoading: false,

      staffRadio: 0,
      value: [],
      staffList: '',
      drawer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: {},
      staffListID: 0,
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    dayjs,
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadWaterData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadWaterData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    searchQuery() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadWaterData()
    },
    // 添加水表
    addPatrol(){
      this.loadWaterData()
      this.patrolDrawer = true
    },
    // 获取水表
    loadWaterData() {
      this.tableLoading = true
      this.$ajax.post('meterQuery', {
        pageNum: this.page,
        pageSize: this.size,
        waterMeterCode: this.formInline.waterMeterCode?this.formInline.waterMeterCode:null,
        userCode: this.formInline.userCode?this.formInline.userCode:null,
        userName: this.formInline.userName?this.formInline.userName:null,
        idNumber: this.formInline.idNumber?this.formInline.idNumber:null,
        regionId: this.formInline.regionId?this.formInline.regionId:null,
        zoneId: this.formInline.zoneId?this.formInline.zoneId:null,
        userStatus: this.formInline.userStatus?this.formInline.userStatus:null
      }).then(res => {
        this.tableDataCp = res.data
        this.total = res.total
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    // 勾选设备
    checkeChange(row) {
      this.waterRadio = row.id
      this.tableSelectList = row
    },
    // 选择巡视点点击确定
    patrolDrawerOK(){
      this.selectPatrolData = []
      this.selectPatrolData.push(this.tableSelectList)
      console.log(this.selectPatrolData)
      this.patrolDrawer = false
    },

    // 选择人员
    checked(row) {
      this.staffRadio = row.id
      this.checkedStaffList = row
    },

    drawerOK(){
      if(!this.checkedStaffList){
        this.$message.error('请选择人员！')
        return
      }
      this.staffListID = this.checkedStaffList.id
      this.staffList = this.checkedStaffList.name
      this.drawer = false;
    },
    // 查询人员数据
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        this.tableData = res.data
      })
    },
    // 切换部门
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 获取部门
    loadTreeData() {
      this.$ajax.get('departmentTree').then(res => {
        this.treeData = res.data
      })
    },
    // 选择外勤人员
    addStaff() {
      this.loadTableData()
      this.drawer = true
    },
    // 提交数据
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.selectPatrolData.length==0) {
            this.$message.error("水表信息不能为空！");
            return
          }

          if(!this.staffList) {
            this.$message.error("人员配置不能为空！");
            return
          }

          let form = {
            endDate: this.form.date,
            source: 0,
            staffId: this.staffListID,
            startDate: new Date(new Date().toLocaleDateString()).getTime(),
            taskDescribe: this.form.taskDescribe,
            specificTaskName: this.form.taskName,
            userCode: this.selectPatrolData[0].userCode,
            waterMeterCode: this.selectPatrolData[0].waterMeterCode
          }

          this.loading = true
          this.$ajax.post("specificCreate", form).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/directionalTask");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
  },
  mounted() {
    this.loadTreeData()
    this.getLastPage()
    this.getSize()
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.search {
  padding: 10px;
}
.table-water-tree {
  display: flex;
  height: ~'calc(100% - 230px)';
  // margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  // margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 325px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
  transition: all .3s ease;
}
.staffBox:hover {
  border: 1px solid #afaaaa;
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>